export default {
  apis: null,
  definition: {
    actions: null,
    fields: [
      {
        display: {
          classes: ["pb-2"],
          attributes: {
            search_disabled: true,
          },
        },
        label: "",
        name: "_created_by",
        type: "user_badge",
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-4", "mr-2"],
        },
        label: "",
        mode: "display",
        name: "friends",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-account-multiple",
          title: "Friends",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-4", "mr-2"],
        },
        label: "",
        mode: "display",
        name: "groups",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-account-group",
          title: "Groups",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-4", "mr-2"],
        },
        label: "",
        mode: "display",
        name: "marketplace",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-store-outline",
          title: "Marketplace",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-4", "mr-2"],
        },
        label: "",
        mode: "display",
        name: "watch",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-laptop",
          title: "Watch",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-4", "mr-2"],
        },
        label: "",
        mode: "display",
        name: "memories",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-av-timer",
          title: "Memories",
        },
      },
    ],
  },
  display: {
    classes: ["rounded-lg", "px-4", "transparent", "elevation-0"],
    mode: "form",
  },
  title: "",
  type: "form",
};
