export default {
  apis: {
    data: {
      _sort: "uploaded_date",
      refresh_interval: 300000,
      type: "get",
      url: "{_context->path}/documents",
    },
  },
  definition: {
    actions: [
      {
        id: "upload_document",
        label: "Upload",
        name: "add_document",
        type: "action",
        value: {
          type: "dialog",
          url: "{_context->path}/documents?page=create",
        },
      },
    ],
    fields: [
      {
        display: {
          attributes: {
            label: {
              classes: ["behavior_block"],
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["pa-0"],
          embed: {
            width: 150,
          },
          width: "3",
        },
        label: "File",
        mode: "embed",
        name: "file",
        type: "file",
      },
      {
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["font-weight-medium", "text-center"],
          width: "3",
        },
        label: "Uploaded Date",
        mode: "display",
        name: "_created_date",
        type: "date",
      },
      {
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["font-weight-medium", "text-center"],
          width: "3",
        },
        label: "Uploaded By",
        mode: "display",
        name: "_created_by",
        type: "username",
      },
      {
        display: {
          width: "3",
        },
        label: "Actions",
        mode: "display",
        name: "actions",
        type: "actions",
        value: [
          {
            confirmation: "This is an irreversible action. Are you sure?",
            display: {
              attributes: {
                color: "error",
                outlined: true,
                text: "true",
              },
              classes: ["pa-4"],
            },
            icon: "mdi-delete",
            label: "Delete",
            name: "delete",
            type: "action",
            value: {
              success: {
                message: "Deletion successful",
              },
              type: "delete",
              url: "{_context->path}/documents/{_row->id}",
            },
          },
        ],
      },
    ],
    title: "",
  },
  display: {
    attributes: {
      cards: {
        classes: ["pa-4", "rounded-lg", "elevation-0"],
        width: "12",
      },
      no_data: {
        hide_message: false,
        message: "No documents uploaded",
      },
    },
    classes: ["px-2", "transparent", "elevation-0"],
    mode: "cards",
    width: "12",
  },
  id: "document_list",
  title: "Documents",
  type: "list",
};
