const edit_ticket_page = require("./edit_ticket_page.json");
const tickets_list_page = require("./tickets_list_page.json");
const create_ticket_page = require("./create_ticket_page.json");

const lists = [
  {
    id: "simple_list",
    label: "Simple List",
    value: {
      type: "list",
      title: "Tickets",
      subtitle:
        "Lorem Lorem tempor ex in nisi voluptate ad do velit labore duis.",
      definition: {
        fields: [
          {
            label: "Summary",
            name: "title",
            type: "text",
          },
          {
            label: "Assignee",
            name: "assignee",
            mode: "display",
            type: "select",
            options: {
              apis: {
                data: {
                  url: "users",
                  type: "get",
                },
              },
            },
          },
          {
            label: "Type",
            name: "type",
            mode: "display",
            type: "select",
            options: {
              value: [
                {
                  label: "Bug",
                  id: "bug",
                },
                {
                  label: "Enhancement",
                  id: "enhancement",
                },
              ],
            },
          },
          {
            label: "Created",
            name: "_created_by",
            mode: "display",
            type: "date",
          },
          {
            label: "Modified",
            name: "modified",
            mode: "display",
            type: "date",
          },
        ],
        actions: [],
      },
      apis: {
        data: {
          url: "",
          type: "get",
        },
      },
    },
  },
];
const pages = [
  {
    id: "edit_ticket_page",
    label: "Ticket Management: Edit Ticket",
    value: Object.assign(
      {
        type: "page",
      },
      edit_ticket_page
    ),
  },
  {
    id: "tickets_list_page",
    label: "Ticket Management: Tickets List",
    value: Object.assign(
      {
        type: "page",
      },
      tickets_list_page
    ),
  },
  {
    id: "create_ticket_page",
    label: "Ticket Management: Create Ticket",
    value: Object.assign(
      {
        type: "page",
      },
      create_ticket_page
    ),
  },
];

export { pages, lists };
