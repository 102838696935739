export default {
  definition: {
    fields: [
      {
        display: {
          classes: ["px-2"],
          attributes: {
            behavior_no_controls: true,
            label: {
              classes: ["primary--text"],
              raw_html: true,
            },
          },
        },
        label: "<h4>What's on your mind?</h4>",
        click_action: {
          type: "dialog",
          url: "/applications/schoolbus?page=add_post",
        },
        mode: "input",
        name: "details",
        type: "rich_text_editor",
      },
      {
        label: "Actions",
        display: {
          width: "15",
          classes: ["px-4", "pt-2", "pb-4"],
          attributes: {
            position: "bottom_spread",
          },
        },
        name: "actions",
        type: "actions",
        value: [
          {
            name: "live_video",
            icon: "mdi-video-vintage",
            label: "Live Video",
            type: "action",
            display: {
              classes: ["font-weight-bold"],
              attributes: {
                text: true,
                color: "secondary",
                icon: {
                  classes: ["pink--text", "pr-2"],
                },
              },
            },
            value: {
              type: "event",
              name: "dummy_event",
            },
          },
          {
            name: "photo_video",
            icon: "mdi-file-image-outline",
            label: "Photo/Video",
            type: "action",
            display: {
              classes: ["font-weight-bold"],
              attributes: {
                text: true,
                color: "secondary",
                icon: {
                  classes: ["green--text", "pr-2"],
                },
              },
            },
            value: {
              type: "event",
              name: "dummy_event",
            },
          },
          {
            name: "feeling_activity",
            icon: "mdi-emoticon-outline",
            label: "Feeling/Activity",
            type: "action",
            display: {
              classes: ["font-weight-bold"],
              attributes: {
                text: true,
                color: "secondary",
                icon: {
                  classes: ["orange--text", "pr-2"],
                },
              },
            },
            value: {
              type: "event",
              name: "dummy_event",
            },
          },
        ],
      },
    ],
  },
  display: {
    classes: ["rounded-lg"],
  },
  title: "",
  type: "form",
};
