export default {
  apis: {
    data: {
      refresh_interval: 300000,
      type: "get",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [
      {
        display: {
          attributes: {
            color: "secondary",
          },
        },
        label: "Add",
        name: "add_action",
        type: "action",
        value: {
          type: "dialog",
          url: "{_context->path}?page=create",
        },
      },
    ],
    fields: [
      {
        display: {
          attributes: {
            avatar: {
              attributes: {
                behavior_centered: false,
              },
              classes: ["rounded"],
              width: "124",
            },
            if_missing: "placeholder",
            label: {
              display: {
                mode: "hidden ",
              },
            },
          },
          classes: ["pt-4"],
          embed: {
            width: "240",
          },
          mode: "avatar",
          width: "3",
        },
        id: "contact_logo",
        label: "Logo",
        mode: "display",
        name: "contact_image",
        type: "image",
      },
      {
        display: {
          width: "1",
        },
        mode: "display",
        type: "spacer",
      },
      {
        display: {
          width: "7",
        },
        fields: [
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                },
              },
              classes: ["text-h6", "primary--text"],
              width: "12",
            },
            label: "Name",
            mode: "display",
            name: "name",
            type: "text",
          },
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                },
              },
              width: 12,
            },
            label: "Address",
            mode: "display",
            name: "address",
            type: "address",
          },
        ],
        is_container: true,
        mode: "input",
        name: "contact_object",
        type: "object",
      },
      {
        display: {
          attributes: {
            cards: {
              position: "bottom_right",
            },
          },
          classes: ["pa-4"],
        },
        label: "Actions",
        mode: "display",
        name: "actions",
        type: "actions",
        value: [
          {
            display: {
              attributes: {},
            },
            hint: "Edit",
            icon: "mdi-pencil",
            name: "edit",
            type: "action",
            value: {
              type: "navigation",
              url: "{_row->_url}?page=edit",
            },
          },
          {
            confirmation: "This is an irreversible action. Are you sure?",
            display: {
              attributes: {
                color: "error",
              },
            },
            hint: "Delete",
            icon: "mdi-delete",
            name: "delete",
            type: "action",
            value: {
              success: {
                message: "Deletion successful",
              },
              type: "delete",
              url: "{_row->_url}",
            },
          },
        ],
      },
    ],
    mode: "display",
  },
  display: {
    attributes: {
      cards: {
        classes: ["rounded-lg", "px-2", "elevation-0"],
        width: "6",
      },
      flat: true,
      no_data: {
        hide_message: false,
        message: "No contacts added",
      },
    },
    classes: ["transparent", "pt-2"],
    mode: "cards",
    width: "12",
  },
  id: "contact_list",
  title: "Contacts",
  type: "list",
};
