export default {
  type: "form",
  apis: {
    data: {
      url: "{_context->path}",
      type: "get",
    },
  },
  definition: {
    mode: "readonly",
    fields: [
      {
        _system_constraints: {
          _primary_key: true,
        },

        label: "ID",
        mandatory: true,
        mode: "display",
        name: "id",
        type: "text",
      },
      {
        name: "name",
        mandatory: true,
        label: "Name",
        type: "text",
      },
    ],
    actions: [],
  },
};
