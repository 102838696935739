export default {
  apis: {
    data: {
      type: "get",
      url: "{_context->path}",
    },
    submit: {
      params: {
        action: "update_fields",
      },
      success: {
        action: {
          type: "navigation",
          url: "BACK",
        },
        message: "Contact updated successfully",
      },
      type: "post",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [
      {
        _ignore_in_page_editor_: true,
        display: {
          attributes: {
            color: "secondary",
          },
          classes: ["behavior_atman_button"],
        },
        label: "Cancel",
        name: "cancel",
        type: "action",
        value: {
          type: "_cancel_",
        },
      },
      {
        confirmation: "This is an irreversible action. Are you sure?",
        display: {
          attributes: {
            color: "error",
          },
        },
        label: "Delete",
        name: "delete",
        type: "action",
        value: {
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Contact deleted",
          },
          type: "delete",
          url: "{_context->path}",
        },
      },
      {
        _ignore_in_page_editor_: true,
        disabled: false,
        display: {
          attributes: {
            color: "primary",
          },
          classes: ["mr-2", "mt-lg-0", "mt-2", "behavior_atman_button"],
        },
        label: "Submit",
        name: "submit",
        submit: true,
        type: "action",
        value: {
          params: {
            action: "update_fields",
          },
          success: {
            action: {
              type: "navigation",
              url: "BACK",
            },
            message: "Contact updated successfully",
          },
          type: "post",
          url: "{_context->path}",
        },
      },
    ],
    fields: [
      {
        _order: 2,
        display: {
          attributes: {
            placeholder: "/no_image.jpg",
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          embed: {
            width: "240",
          },
          width: "3",
        },
        id: "contact_image",
        label: "Image",
        mode: "input",
        name: "contact_image",
        type: "image",
      },
      {
        display: {
          width: "9",
        },
        fields: [
          {
            label: "Name",
            mandatory: true,
            mode: "input",
            name: "name",
            type: "text",
          },
          {
            display: {
              attributes: {
                label: {
                  display: {
                    mode: "hidden",
                  },
                  raw_html: true,
                },
              },
              width: "12",
            },
            label: "Address",
            mode: "input",
            name: "address",
            type: "address",
          },
        ],
        is_container: true,
        mode: "input",
        name: "contact_object",
        type: "object",
      },
    ],
  },
  display: {
    classes: ["px-2", "transparent", "elevation-0"],
  },
  title: "Edit Details",
  type: "form",
};
