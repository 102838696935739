import notes_create_page from "./notes_create_page";
import notes_edit_page from "./notes_edit_page";
import notes_list from "./notes_list";
import notes_create_form from "./notes_create_form";
import notes_edit_form from "./notes_edit_form";

const lists = [
  {
    id: "notes_list",
    label: "Notes: List",
    value: notes_list,
  },
];

const pages = [
  {
    id: "notes_create_page",
    label: "Notes: Create Page",
    value: Object.assign(
      {
        type: "page",
      },
      notes_create_page
    ),
  },
  {
    id: "notes_edit_page",
    label: "Notes: Edit Page",
    value: Object.assign(
      {
        type: "page",
      },
      notes_edit_page
    ),
  },
];

const forms = [
  {
    id: "notes_create_form",
    label: "Notes: Create",
    value: notes_create_form,
  },
  {
    id: "notes_edit_form",
    label: "Notes: edit",
    value: notes_edit_form,
  },
];

export { forms, lists, pages };
