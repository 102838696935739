export default {
  type: "form",
  apis: {
    data: {
      url: "{_context->path}",
      type: "get",
    },
  },
  definition: {
    mode: "readonly",
    fields: [
      {
        name: "name",
        mandatory: true,
        label: "Name",
        type: "text",
      },
      {
        label: "Phone",
        name: "phone",
        rules: [
          {
            phone_number: true,
          },
        ],
        type: "text",
      },
      {
        label: "Email",
        mandatory: true,
        name: "email",
        rules: [
          {
            email: true,
          },
        ],
        type: "text",
      },
    ],
    actions: [],
  },
};
