export default {
  type: "form",
  apis: {
    submit: {
      url: "{_context->path}",
      type: "post",
      success: {
        action: {
          type: "navigation",
          url: "BACK",
        },
        message: "Creation successful",
      },
    },
  },
  definition: {
    fields: [
      {
        _system_constraints: {
          _primary_key: true,
        },

        label: "ID",
        mandatory: true,
        mode: "input",
        name: "id",
        type: "text",
      },
      {
        name: "name",
        mandatory: true,
        label: "Name",
        type: "text",
      },
    ],
    actions: [],
  },
};
