export default {
  apis: {
    data: {
      refresh_interval: 300000,
      type: "get",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [
      {
        display: {
          attributes: {
            color: "secondary",
          },
        },
        label: "Add",
        name: "add_action",
        type: "action",
        value: {
          type: "dialog",
          url: "{_context->path}?page=create",
        },
      },
    ],
    fields: [
      {
        _order: 4,
        display: {
          attributes: {
            avatar: {
              classes: ["rounded"],
              width: "84",
            },
            if_missing: "placeholder",
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["pt-4"],
          embed: {
            width: "240",
          },
          mode: "avatar",
        },
        id: "image",
        label: "Image",
        mode: "display",
        name: "image",
        type: "image",
      },
      {
        display: {
          attributes: {
            behavior_centered: true,
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["font-weight-medium", "text-h6", "pt-2"],
        },
        label: "Name",
        mode: "display",
        name: "name",
        type: "text",
      },
      {
        _order: 4,
        display: {
          attributes: {
            behavior_centered: true,
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["text-caption", "font-weight-medium"],
        },
        id: "email_id",
        label: "Email ID",
        mandatory: false,
        mode: "display",
        name: "email_id",
        rules: [
          {
            email: true,
          },
        ],
        type: "text",
      },
      {
        display: {
          attributes: {
            behavior_centered: true,
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: [],
        },
        label: "Role",
        mode: "display",
        name: "role",
        type: "text",
      },
      {
        display: {
          attributes: {
            behavior_centered: true,
            label: {
              display: {
                mode: "hidden",
              },
            },
          },
          classes: ["behaviour_metadata", "font-weight-medium"],
        },
        label: "Experience",
        mode: "display",
        name: "experience",
        type: "text",
      },
      {
        display: {
          attributes: {
            cards: {
              position: "bottom_right",
            },
          },
          classes: ["py-4"],
        },
        label: "Actions",
        mode: "display",
        name: "actions",
        type: "actions",
        value: [
          {
            display: {
              attributes: {},
            },
            hint: "Edit",
            icon: "mdi-pencil",
            name: "edit",
            type: "action",
            value: {
              type: "navigation",
              url: "{_row->_url}?page=edit",
            },
          },
          {
            confirmation: "This is an irreversible action. Are you sure?",
            display: {
              attributes: {
                color: "error",
              },
            },
            hint: "Delete",
            icon: "mdi-delete",
            name: "delete",
            type: "action",
            value: {
              success: {
                message: "Deletion successful",
              },
              type: "delete",
              url: "{_row->_url}",
            },
          },
        ],
      },
    ],
    mode: "display",
  },
  display: {
    attributes: {
      cards: {
        classes: ["rounded-lg", "px-2", "elevation-0"],
        width: "3",
      },
      flat: true,
      no_data: {
        hide_message: false,
        message: "No contacts added",
      },
    },
    classes: ["transparent"],
    mode: "cards",
    width: "12",
  },
  id: "list",
  type: "list",
};
