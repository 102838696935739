import contact_create_form from "./forms/create";
import contact_view_form from "./forms/view";
import contact_edit_form from "./forms/update";
import contact_list from "./lists/list";
import list_variant from "./lists/list_variant";
import create_variant from "./forms/create_variant";
import edit_variant from "./forms/edit_variant";
import contact_list_badge from "./lists/list_badge";

const forms = [
  {
    id: "contact_create_form",
    label: "Contact create form",
    value: Object.assign({ type: "form" }, contact_create_form),
  },
  {
    id: "contact_view_form",
    label: "Contact view form",
    value: Object.assign({ type: "form" }, contact_view_form),
  },
  {
    id: "contact_edit_form",
    label: "Contact edit form",
    value: Object.assign({ type: "form" }, contact_edit_form),
  },
  {
    id: "contact_create_form_variant",
    label: "Contact create form (variant)",
    value: Object.assign({ type: "form" }, create_variant),
  },
  {
    id: "contact_edit_form_variant",
    label: "Contact edit form (variant)",
    value: Object.assign({ type: "form" }, edit_variant),
  },
];

const lists = [
  {
    id: "contact_list",
    label: "Contact List",
    value: contact_list,
  },
  {
    id: "contact_list_variant",
    label: "Contact Cards List",
    value: list_variant,
  },
  {
    id: "contact_list_badge",
    label: "Contact List Badge",
    value: contact_list_badge,
  },
];

const pages = [
  {
    id: "create_contact",
    label: "Contact: Create",
    value: {
      type: "page",
      id: "create",
      title: "Create",
      subtitle: "",
      content: [contact_create_form],
    },
  },
  {
    id: "edit_contact",
    label: "Contact: View/Edit",
    value: {
      type: "page",
      id: "update",
      title: "Update",
      subtitle: "",
      content: [contact_edit_form],
    },
  },
  {
    id: "list_contact",
    label: "Contact: List",
    value: {
      type: "page",
      id: "list",
      title: "List",
      subtitle: "",
      content: [contact_list],
    },
  },
  {
    id: "list_contact_variant",
    label: "Contact: Cards List",
    value: {
      type: "page",
      id: "list",
      title: "List",
      subtitle: "",
      content: [list_variant],
    },
  },
  {
    id: "list_contact_badge",
    label: "Contact: Badge List",
    value: {
      type: "page",
      id: "list",
      title: "List",
      subtitle: "",
      content: [contact_list_badge],
    },
  },
  {
    id: "create_contact_variant",
    label: "Contact: Create (variant)",
    value: {
      type: "page",
      id: "create",
      title: "Create",
      subtitle: "",
      content: [create_variant],
    },
  },
  {
    id: "edit_contact_variant",
    label: "Contact: Edit (variant)",
    value: {
      type: "page",
      id: "update",
      title: "Update",
      subtitle: "",
      content: [edit_variant],
    },
  },
];

export { forms, pages, lists };
