export default {
  apis: {
    data: {
      type: "get",
      url: "{_context->path}",
    },
    submit: {
      params: {
        action: "update_fields",
      },
      success: {
        message: "Note updated",
        action: {
          type: "event",
          name: "_close_dialog",
        },
      },
      type: "post",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [
      {
        name: "delete",
        label: "Delete",
        type: "action",
        confirmation: "This is an irreversible action. Are you sure?",
        value: {
          type: "delete",
          url: "{_context->path}",
          success: {
            message: "Deletion successful",
            action: {
              type: "navigation",
              url: "BACK",
            },
          },
        },
        display: {
          attributes: {
            color: "error",
          },
        },
      },
    ],
    fields: [
      {
        mode: "display",
        label: "Created",
        name: "_created_date",
        type: "date",
      },
      {
        label: "Note",
        name: "note",
        type: "textarea",
      },
      {
        label: "Due Date",
        name: "due_date",
        type: "date",
      },
    ],
  },
  type: "form",
};
