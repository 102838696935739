import user_create_form from "./forms/create";
import { PAGES } from "@/constants.js";
const forms = [
  {
    id: "create_user_form",
    label: "User create form",
    value: Object.assign({ type: "form" }, user_create_form),
  },
];

const lists = [];

const pages = [
  {
    id: PAGES.CREATE_USER,
    label: "User: Create",
    value: {
      type: "page",
      id: "create",
      title: "Create",
      subtitle: "",
      content: [user_create_form],
    },
  },
];

export { forms, pages, lists };
