export default {
  type: "form",
  title: "Contact",
  apis: {
    data: {
      url: "{_context->path}",
      type: "get",
    },
    submit: {
      url: "{_context->path}",
      type: "post",
      params: {
        action: "update_fields",
      },
      success: {
        action: {
          type: "navigation",
          url: "BACK",
        },
        message: "Update Successful",
      },
    },
  },
  definition: {
    fields: [
      {
        name: "name",
        mandatory: true,
        label: "Name",
        type: "text",
      },
      {
        label: "Phone",
        name: "phone",
        rules: [
          {
            phone_number: true,
          },
        ],
        type: "text",
      },
      {
        label: "Email",
        mandatory: true,
        name: "email",
        rules: [
          {
            email: true,
          },
        ],
        type: "text",
      },
    ],
    actions: [
      {
        name: "delete",
        label: "Delete",
        type: "action",
        confirmation: "This is an irreversible action. Are you sure?",
        value: {
          type: "delete",
          url: "{_context->path}",
          success: {
            message: "Deletion successful",
            action: {
              type: "navigation",
              url: "BACK",
            },
          },
        },
        display: {
          attributes: {
            color: "error",
          },
        },
      },
    ],
  },
};
