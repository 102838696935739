export default {
  apis: {
    data: {
      count: "5",
      refresh_interval: 300000,
      type: "get",
      url: "/get_posts",
    },
  },
  definition: {
    actions: [
      {
        display: {
          classes: ["mt-n4"],
          attributes: {
            small: true,
            text: true,
            color: "secondary",
          },
        },
        icon: "mdi-video",
        hint: "New Room",
        name: "video",
        type: "action",
        value: {
          type: "event",
          name: "dummy_event",
        },
      },
      {
        display: {
          classes: ["mt-n4"],
          attributes: {
            small: true,
            text: true,
            color: "secondary",
          },
        },
        icon: "mdi-magnify",
        hint: "Search for a name or group",
        name: "search",
        type: "action",
        value: {
          type: "event",
          name: "dummy_event",
        },
      },
      {
        display: {
          classes: ["mt-n4"],
          attributes: {
            small: true,
            text: true,
            color: "secondary",
          },
        },
        icon: "mdi-dots-horizontal",
        hint: "Options",
        name: "options",
        type: "action",
        value: {
          type: "event",
          name: "dummy_event",
        },
      },
    ],
    fields: [
      {
        label: "Name",
        mode: "display",
        name: "_created_by",
        type: "user_badge",
      },
    ],
    mode: "display",
  },
  display: {
    mode: "list",
    width: "12",
    classes: ["px-4", "transparent", "elevation-0"],
    flat: true,
    attributes: {
      "disable-filtering": true,
      actions_position: "bottom_spread",
    },
  },
  id: "contacts_list",
  type: "list",
  title: "Contacts",
};
