import {
  pages as ticket_management_pages,
  lists as ticket_management_lists,
} from "./ticket_management";
import { pages as application_home_pages } from "./application";
import {
  forms as application_update_form,
  pages as application_update_page,
} from "./application";
import {
  forms as entity_forms,
  pages as entity_pages,
  lists as entity_lists,
} from "./entity";

import {
  forms as contact_forms,
  pages as contact_pages,
  lists as contact_lists,
} from "./contact";

import {
  forms as user_forms,
  pages as user_pages,
  lists as user_lists,
} from "./user";

import {
  forms as document_forms,
  pages as document_pages,
  lists as document_lists,
} from "./documents";

import {
  forms as note_forms,
  pages as note_pages,
  lists as note_lists,
} from "./notes";

import {
  forms as panels_forms,
  pages as panels_pages,
  lists as panels_lists,
} from "./panels";

const emptyForm = {
  id: "none",
  label: "Empty",
  value: {
    type: "form",
    title: "",
    subtitle: "",
    apis: {},
    definition: {
      fields: [],
      actions: [],
    },
  },
};

const forms = [
  emptyForm,
  {
    id: "contact_form",
    label: "Simple Ticket Form",
    value: {
      type: "form",
      title: "Contact us",
      subtitle: "Labore cupidatat qui velit dolore deserunt anim cillum.",
      apis: {},
      definition: {
        fields: [
          {
            name: "title",
            mandatory: true,
            label: "Title",
            type: "text",
          },
          {
            name: "assignee",
            mandatory: true,
            label: "Assignee",
            type: "select",
            options: {
              apis: {
                data: {
                  url: "users",
                  type: "get",
                },
              },
            },
          },
          {
            name: "description",
            mandatory: true,
            label: "Description",
            type: "rich_text_editor",
          },
          {
            name: "type",
            mandatory: true,
            label: "Type",
            type: "select",
            options: {
              value: [
                {
                  label: "Bug",
                  id: "bug",
                },
                {
                  label: "Enhancement",
                  id: "enhancement",
                },
              ],
            },
          },
          {
            name: "priority",
            mandatory: true,
            label: "Priority",
            type: "select",
            options: {
              value: [
                {
                  label: "P1",
                  id: "p1",
                },
                {
                  label: "P2",
                  id: "p2",
                },
              ],
            },
          },
        ],
        actions: [],
      },
    },
  },
  ...application_update_form,
  ...entity_forms,
  ...contact_forms,
  ...user_forms,
  ...note_forms,
  ...document_forms,
  ...panels_forms,
];

const emptyList = {
  id: "none",
  label: "Empty",
  value: {
    type: "list",
    title: "",
    subtitle: "",
    definition: {
      fields: [],
      actions: [],
    },
    apis: {
      data: {
        url: "",
        type: "get",
      },
    },
  },
};

const lists = [
  emptyList,
  ...note_lists,
  ...entity_lists,
  ...contact_lists,
  ...user_lists,
  ...document_lists,
  ...ticket_management_lists,
  ...panels_lists,
];

const emptyPage = {
  id: "empty",
  label: "Empty Page",
  value: {
    type: "page",
    id: "empty",
    content: [],
  },
};

const pages = [
  emptyPage,
  ...note_pages,
  ...entity_pages,
  ...contact_pages,
  ...application_update_page,
  ...user_pages,
  ...document_pages,
  ...application_home_pages,
  ...ticket_management_pages,
  ...panels_pages,
];

const templates = [...pages, ...forms, ...lists];
export default templates;
