export default {
  apis: {
    submit: {
      success: {
        action: {
          type: "navigation",
          url: "BACK",
        },
        message: "Note added",
      },
      type: "post",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [],
    fields: [
      {
        label: "Note",
        mandatory: true,
        name: "note",
        type: "textarea",
      },
      {
        label: "Due Date",
        name: "due_date",
        type: "date",
      },
    ],
  },
  type: "form",
};
