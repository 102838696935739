export default {
  apis: {
    data: {
      refresh_interval: 300000,
      type: "get",
      _sort: "uploaded_date",
      url: "{_context->path}/documents",
    },
  },
  definition: {
    actions: [
      {
        label: "Upload",
        name: "add_document",
        type: "action",
        value: {
          type: "dialog",
          url: "{_context->path}/documents?page=create",
        },
      },
    ],
    fields: [
      {
        label: "File",
        mode: "embed",
        name: "file",
        type: "file",
        display: {
          embed: {
            width: 150,
          },
        },
      },
      {
        label: "Description",
        name: "description",
        type: "textarea",
        mode: "display",
      },
      {
        label: "Actions",
        mode: "display",
        name: "actions",
        type: "actions",
        value: [
          {
            confirmation: "This is an irreversible action. Are you sure?",
            display: {
              attributes: {
                color: "error",
              },
            },
            icon: "mdi-delete",
            hint: "Delete",
            name: "delete",
            type: "action",
            value: {
              success: {
                message: "Deletion successful",
              },
              type: "delete",
              url: "{_context->path}/documents/{_row->id}",
            },
          },
        ],
      },
    ],
    title: "",
  },
  display: {
    width: "12",
  },
  id: "list",
  title: "Documents",
  type: "list",
};
