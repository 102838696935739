export default {
  apis: {
    data: {
      refresh_interval: 300000,
      type: "get",
      url: "{_context->path}/notes",
    },
  },
  definition: {
    actions: [
      {
        display: {
          attributes: {
            color: "primary",
          },
        },
        label: "Add Note",
        name: "add_note",
        type: "action",
        value: {
          type: "navigation",
          url: "{_context->path}/notes?page=create",
        },
      },
    ],
    fields: [
      {
        label: "Note",
        mode: "display",
        name: "note",
        type: "textarea",
      },
      {
        label: "Created",
        mode: "display",
        name: "_created_date",
        type: "date",
      },
      {
        label: "Due Date",
        mode: "display",
        name: "due_date",
        type: "date",
      },
      {
        label: "Actions",
        mode: "display",
        name: "actions",
        type: "actions",
        value: [
          {
            display: {
              attributes: {},
            },
            icon: "mdi-pencil",
            hint: "Edit",
            name: "edit",
            type: "action",
            value: {
              type: "dialog",
              url: "{_row->_url}?page=edit",
            },
          },
          {
            confirmation: "This is an irreversible action. Are you sure?",
            display: {
              attributes: {
                color: "error",
              },
            },
            icon: "mdi-delete",
            hint: "Delete",
            name: "delete",
            type: "action",
            value: {
              success: {
                message: "Deletion successful",
              },
              type: "delete",
              url: "{_row->_url}",
            },
          },
        ],
      },
    ],
    mode: "display",
  },
  display: {
    width: "12",
  },
  id: "list",
  title: "Notes",
  type: "list",
};
