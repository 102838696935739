import entity_create_form from "./forms/create";
import entity_view_form from "./forms/view";
import entity_edit_form from "./forms/update";
import entity_list from "./lists/list";

const forms = [
  {
    id: "entity_create_form",
    label: "Entity create form",
    value: Object.assign({ type: "form" }, entity_create_form),
  },
  {
    id: "entity_view_form",
    label: "Entity view form",
    value: Object.assign({ type: "form" }, entity_view_form),
  },
  {
    id: "entity_edit_form",
    label: "Entity edit form",
    value: Object.assign({ type: "form" }, entity_edit_form),
  },
];

const lists = [
  {
    id: "entity_list",
    label: "Entity List",
    value: entity_list,
  },
];

const pages = [
  {
    id: "create_entity",
    label: "Entity: Create",
    value: {
      type: "page",
      id: "create",
      title: "Create",
      subtitle: "",
      content: [entity_create_form],
    },
  },
  {
    id: "edit_entity",
    label: "Entity: Edit/Update",
    value: {
      type: "page",
      id: "update",
      title: "Update",
      subtitle: "",
      content: [entity_edit_form],
    },
  },
  {
    id: "list_entity",
    label: "Entity: List",
    value: {
      type: "page",
      id: "list",
      title: "List",
      subtitle: "",
      content: [entity_list],
    },
  },
];

export { forms, pages, lists };
