import documents_list from "./documents_list";
import upload_document_form from "./upload_document_form";
import upload_document_dialog from "./upload_document_dialog";
import documents_cards_list from "./documents_cards_list";

const forms = [
  {
    id: "upload_document_form",
    label: "Documents: Upload",
    value: Object.assign(
      {
        type: "form",
      },
      upload_document_form
    ),
  },
];
const lists = [
  {
    id: "documents_list",
    label: "Documents: List",
    value: Object.assign(
      {
        type: "list",
      },
      documents_list
    ),
  },
  {
    id: "documents_cards_list",
    label: "Documents: Cards List",
    value: Object.assign(
      {
        type: "list",
      },
      documents_cards_list
    ),
  },
];

const pages = [
  {
    id: "upload_document_dialog",
    label: "Documents: Upload Dialog",
    value: Object.assign(
      {
        type: "page",
      },
      upload_document_dialog
    ),
  },
];

export { forms, lists, pages };
