import links_form from "./links_form";
import contacts_list from "./contacts_list";
import shortcuts_form from "./shortcuts_form";
import write_post_form from "./write_post_form";
import posts_list from "./posts_list";

const forms = [
  {
    id: "links_form",
    label: "Panel Links Form",
    value: links_form,
  },
  {
    id: "shortcuts_form",
    label: "Panel Shortcuts Form",
    value: shortcuts_form,
  },
  {
    id: "write_post_form",
    label: "Panel Write Post Form",
    value: write_post_form,
  },
];
const lists = [
  {
    id: "contacts_list",
    label: "Panel Contacts List",
    value: contacts_list,
  },
  {
    id: "posts_list",
    label: "Panel Posts List",
    value: posts_list,
  },
];

const pages = [
  {
    id: "links_form",
    label: "Panel Links Form",
    value: {
      type: "page",
      id: "create",
      title: "Links",
      subtitle: "",
      classes: ["behaviour_border_down"],
      content: [links_form],
    },
  },
  {
    id: "contacts_list",
    label: "Panel Contacts List",
    value: {
      type: "page",
      id: "list",
      title: "Contacts",
      subtitle: "",
      classes: ["behaviour_border_down"],
      content: [contacts_list],
    },
  },
  {
    id: "shortcuts_form",
    label: "Panel Shortcuts Form",
    value: {
      type: "page",
      id: "create",
      title: "Shortcuts",
      subtitle: "",
      classes: ["behaviour_border_down"],
      content: [shortcuts_form],
    },
  },
];

export { forms, lists, pages };
