export default {
  apis: {
    submit: {
      success: {
        action: {
          name: "_close_dialog",
          type: "event",
        },
        message: "File uploaded",
      },
      type: "post",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [],
    fields: [
      {
        label: "File",
        display: {
          width: "12",
        },
        mode: "input",
        name: "file",
        type: "file",
      },
      {
        label: "Description",
        display: {
          width: "12",
        },
        name: "description",
        type: "textarea",
      },
    ],
  },
  type: "form",
};
