export default {
  apis: {
    submit: {
      success: {
        action: {
          type: "navigation",
          url: "BACK",
        },
        message: "Contact added successfully",
      },
      type: "post",
      url: "{_context->path}",
    },
  },
  definition: {
    actions: [],
    fields: [
      {
        display: {
          classes: ["pt-2"],
        },
        label: "Name",
        mandatory: true,
        mode: "input",
        name: "name",
        type: "text",
      },
      {
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
              raw_html: true,
            },
          },
          classes: ["mt-n4"],
        },
        label: "Address",
        mode: "input",
        name: "address",
        type: "address",
      },
      {
        display: {
          attributes: {
            label: {
              display: {
                mode: "hidden",
              },
            },
            placeholder: "/no_image.jpg",
          },
          embed: {
            width: "240",
          },
          width: "3",
        },
        label: "Image",
        mode: "input",
        name: "contact_image",
        type: "image",
      },
    ],
  },
  display: {
    classes: ["px-2"],
  },
  type: "form",
};
