export default {
  id: "list",
  type: "list",
  display: {
    width: "12",
  },
  definition: {
    mode: "display",
    fields: [
      {
        label: " Name",
        name: "name",
        mode: "display",
        type: "text",
      },
      {
        label: "Actions",
        name: "actions",
        type: "actions",
        value: [
          {
            name: "edit",
            hint: "Edit",
            icon: "mdi-pencil",
            type: "action",
            value: {
              type: "navigation",
              url: "{_row->_url}?page=edit",
            },
            display: {
              attributes: {},
            },
          },
          {
            name: "delete",
            hint: "Delete",
            icon: "mdi-delete",
            type: "action",
            confirmation: "This is an irreversible action. Are you sure?",
            value: {
              type: "delete",
              url: "{_row->_url}",
              success: {
                message: "Deletion successful",
              },
            },
            display: {
              attributes: {
                color: "error",
              },
            },
          },
        ],
      },
    ],
    actions: [
      {
        name: "add_entity",
        label: "Add",
        type: "action",
        value: {
          type: "navigation",
          url: "{_context->path}?page=create",
        },
        display: {
          attributes: {
            color: "secondary",
          },
        },
      },
    ],
  },
  apis: {
    data: {
      url: "{_context->path}",
      refresh_interval: 300000,
      type: "get",
    },
  },
};
