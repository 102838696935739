export default {
  apis: null,
  definition: {
    actions: null,
    fields: [
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pa-0 pb-2"],
        },
        label: "",
        mode: "display",
        name: "posts",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-post-outline",
          title: "Posts",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-2 pa-0"],
        },
        label: "",
        mode: "display",
        name: "replies",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-reply-outline",
          title: "Replies",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-2 pa-0"],
        },
        label: "",
        mode: "display",
        name: "flagged_posts",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-post",
          title: "Flagged Posts",
        },
      },
      {
        display: {
          attributes: {
            style_link: true,
            icon: {
              classes: ["primary--text", "pr-4"],
            },
          },
          classes: ["pb-2 pa-0"],
        },
        label: "",
        mode: "display",
        name: "flagged_replies",
        type: "link",
        value: {
          href: "{_context->path}",
          icon: "mdi-reply",
          title: "Flagged Replies",
        },
      },
    ],
  },
  display: {
    classes: ["rounded-lg", "px-4", "pt-0", "transparent", "elevation-0"],
    mode: "form",
  },
  title: "",
  type: "form",
};
