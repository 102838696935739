export default {
  apis: {
    data: {
      count: "5",
      refresh_interval: 300000,
      type: "get",
      url: "/get_posts",
    },
  },
  display: {
    mode: "cards",
    classes: ["transparent", "pb-4", "mt-n4"],
    attributes: {
      "disable-filtering": true,
      flat: true,
      cards: {
        classes: ["rounded-lg", "pa-4", "elevation-1", "behavior_hover_border"],
      },
    },
  },
  definition: {
    actions: null,
    fields: [
      {
        display: {
          classes: ["font-weight-medium"],
          attributes: {
            search_disabled: true,
          },
        },
        label: "",
        name: "_created_by",
        type: "user_badge",
      },
      {
        display: {
          classes: ["behaviour_metadata"],
          attributes: {
            search_disabled: true,
          },
        },
        format: "elapsed",
        label: "",
        mode: "display",
        name: "_created_date",
        type: "date",
      },
      {
        display: {
          attributes: {
            search_disabled: true,
          },
        },
        mode: "display",
        name: "details",
        label: "",
        type: "rich_text_editor",
      },
      {
        display: {
          classes: ["mb-2"],
          attributes: {
            search_disabled: true,
            embed: {
              width: "500",
            },
          },
        },
        mode: "display",
        name: "image",
        label: "",
        type: "image",
      },
      {
        label: "Actions",
        display: {
          width: "15",
          classes: ["pb-2", "pt-4", "pl-8", "pr-8"],
          attributes: {
            cards: {
              position: "bottom",
            },
          },
        },
        name: "actions",
        type: "actions",
        value: [
          {
            name: "like",
            icon: "mdi-thumb-up-outline",
            label: "Like",
            type: "action",
            display: {
              attributes: {
                text: true,
                color: "secondary",
                icon: {
                  classes: ["pr-2"],
                },
              },
            },
            value: {
              type: "event",
              name: "dummy_event",
            },
          },
          {
            name: "comment",
            icon: "mdi-comment-outline",
            label: "Comment",
            type: "action",
            display: {
              attributes: {
                text: true,
                color: "secondary",
                icon: {
                  classes: ["pr-2"],
                },
              },
            },
            value: {
              type: "event",
              name: "dummy_event",
            },
          },
        ],
      },
    ],
  },
  type: "list",
  title: "",
};
